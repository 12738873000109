<template>
  <div class="subscription-landing subscription-landing--sunset">
    <section class="shaded hero hero--stacked-photo">
      <header class="logo-h1 section_heading">
        <div class="hero-logo">
          <img
            v-lazy-load data-src="~/assets/images/subscription-sunset.svg"
            alt="Sunset Logo"
          />
        </div>
        <h1>The Sunset Wine Club</h1>
      </header>
      <div class="hero-photo site-width">
        <img
          v-lazy-load data-src="~/assets/images/sunset.jpg"
          alt="A toast at Sunset"
          role="presentation"
        />
      </div>
      <header class="section_heading--has_heading section_heading">
        <h2>The Living Story of Wine in the West</h2>
        <p>
          Each wine we’ve chosen brings the poetry and history of California,
          Oregon, and Washington into your glass, delivering the best of the
          West in every box. And each is a moment in time, connected to the
          land, the growers, and the vintners who brought it to life.
        </p>
      </header>
    </section>
    <section class="primary-value">
      <header
        class="section_heading--has_heading section_heading--nodecoration section_heading"
      >
        <h2>
          Six Wines Delivered Quarterly.
        </h2>
        <h3>{{ pricing }}</h3>
        <p>Plus, members enjoy 10% off all Wine Access purchases *</p>
      </header>
    </section>
    <section class="callout">
      <div class="site-width">
        <div class="cta-callout">
          <div class="text">
            <h2>Drink the Best <span class="nowrap">of the West</span></h2>
            <div class="button-link">
              <p>
                <a href="#" class="button primary" @click="signup()">
                  <span class="button-inset">
                    {{ ctaText }}
                  </span>
                </a>
              </p>
              <p v-if="hasActiveGiftTerm">
                <span class="gift-alternative">
                  <nuxt-link
                    to="/wine-club/gifting/sunset-club/"
                    class="button"
                  >
                    Or gift it
                  </nuxt-link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="benefits">
      <div class="site-width">
        <SectionHeading
          title="An Experience Beyond the Bottle"
          is-flush
          description="Subscribe to the Sunset Wine Club and you’re not just getting wine
          shipments. You’re getting a taste of the magazine’s featured
          selections, and the captivating story behind each bottle. It’s an
          invitation to sit and sip at the Sunset table."
        >
          <template #titleSlot>
            <h2>
              An Experience
              <br />
              Beyond the Bottle
            </h2>
          </template>
        </SectionHeading>
        <CardCarousel
          id="club-features"
          :item-count="3"
          hide-pagination
          hide-arrows
          max-page-size="3"
          extra-classes="card_carousel__mobile--stacked card_carousel__mobile--1photo"
        >
          <li>
            <div class="card">
              <span class="image-wrap">
                <span class="image card__image">
                  <picture class="lazyLoad">
                    <img
                      alt="Wine glass next to a wok full of noodles"
                      class="lazyLoad"
                      v-lazy-load data-src="~/assets/images/sunset-recipes.png"
                    />
                  </picture>
                </span>
              </span>
              <h3 class="card__title">
                Sunset Recipes.
                <span class="nowrap">Profound Pairings.</span>
              </h3>
              <p class="description">
                Every bottle comes with exclusive tasting notes and suggested
                pairings for featured Sunset recipes, hand-picked by our team of
                unrivaled experts.
              </p>
            </div>
          </li>
          <li>
            <div class="card">
              <span class="image-wrap">
                <span class="image card__image">
                  <picture class="lazyLoad">
                    <img
                      alt="Hand holding a bottle of wine"
                      class="lazyLoad"
                      v-lazy-load data-src="~/assets/images/sunset-gems.png"
                    />
                  </picture>
                </span>
              </span>
              <h3 class="card__title">
                First Shot at Rare Gems
                <span class="nowrap">and Icons.</span>
              </h3>
              <p class="description">
                You’ll be first in line to claim the newest, highly allocated
                additions to our Sunset wine shop, bottles specially selected to
                shine alongside each issue’s recipes.
              </p>
            </div>
          </li>
          <li>
            <div class="card">
              <span class="image-wrap">
                <span class="image card__image">
                  <picture class="lazyLoad">
                    <img
                      alt="Tilted wine glass"
                      class="lazyLoad"
                      v-lazy-load data-src="~/assets/images/sunset-events.png"
                    />
                  </picture>
                </span>
              </span>
              <h3 class="card__title">
                Exclusive Events.
              </h3>
              <p class="description">
                Join exclusive guided tastings on Zoom with our expert team,
                winemakers, and Sunset editors, who will share their insights on
                each bottle.
              </p>
            </div>
          </li>
        </CardCarousel>
      </div>
    </section>
    <ExpertsSection />
    <section class="cta-callout cta-callout--section-heading">
      <header class="section_heading--has_heading section_heading">
        <h2>
          Every Sip Tells a Story
        </h2>
        <p class="standard">{{ pricing }}</p>
        <div class="button-link">
          <p>
            <a href="#" class="button primary" @click="signup()">
              <span class="button-inset">
                {{ ctaText }}
              </span>
            </a>
          </p>
          <p v-if="hasActiveGiftTerm">
            <span class="gift-alternative">
              <nuxt-link to="/wine-club/gifting/sunset-club/" class="button">
                Or gift it
              </nuxt-link>
            </span>
          </p>
        </div>
      </header>
    </section>
  </div>
</template>
<script>
export default {
  name: 'SunsetIndex',
  components: {
    CardCarousel: () => import('~/components/CardCarousel'),
    ExpertsSection: () => import('~/components/Club/ExpertsSection'),
  },
  data() {
    return {
      isFull: false,
      deliveryFrequency: '',
      pricing: '',
      hasActiveGiftTerm: false,
      modalTheme: {
        title: 'The Living Story of Wine in the West',
        container: {
          classes: 'wineclub-form sunset',
        },
        login: {
          submitButton: {
            classes: 'primary continue',
          },
        },
        register: {
          submitButton: {
            classes: 'primary continue',
            label: 'Continue',
          },
        },
        emailCheck: {
          submitButton: {
            classes: 'primary continue',
            label: 'Continue',
          },
        },
      },
    }
  },
  async fetch() {
    const resp = await this.$axios.get('/api/membership/sunset-club')
    this.isFull = resp.data.plans.every((p) => p.is_full)
    this.deliveryFrequency = resp.data.delivery_freq_info
    this.pricing = resp.data.pricing_info
    this.hasActiveGiftTerm = resp.data.has_active_gift_term
  },
  head() {
    return {
      title: 'The Sunset Wine Club by Wine Access',
    }
  },
  computed: {
    ctaText() {
      if (this.isFull) {
        return 'Join The Waiting List'
      } else {
        return 'Get Started'
      }
    },
  },
  methods: {
    openLoginModal() {
      this.$store.dispatch('toggleLoginModal', {
        modalTheme: this.modalTheme,
        loginRedirect: '/sunset/join/',
        isClub: true,
      })
    },
    signup() {
      if (!this.$auth.loggedIn) {
        this.openLoginModal()
      } else {
        this.$router.push('/sunset/join/')
      }
    },
  },
}
</script>

<style scoped></style>
